<template>
  <div>
    <v-chip
      class="ma-2" 
      label
      :color="isActive(tag)"
      v-for="tag in filteredTags" 
      :key="tag.id"
      @click="toggleTag(tag)"
    >
      <v-progress-circular indeterminate size="20" v-if="loading==tag.id"></v-progress-circular>
      <span v-else>
        <v-icon left>mdi-label</v-icon>
        {{ tag.name }}
      </span>
    </v-chip>

    <slot></slot>
  </div>
</template>

<style type="text/css">
  
</style>

<script>

  import _ from 'lodash'
  import Api from '../../api'

  export default {
    name: 'Tags',

    components: {},

    props: {
      modelType: {
        type: String
      },
      modelId: {
        type: Number
      },
      onlyActive: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      loading: null,
      model: {},
      tags: []
    }),

    mounted()
    {  
      this.loadTags()

      if( this.modelType && this.modelId )
        this.loadModel()
    },

    computed:
    {
      filteredTags()
      {
        return this.onlyActive ? this.model.tags : this.tags
      }
    },

    methods: {
      
      isActive(tag)
      {
        let isActive = _.find(this.model.tags, {id:tag.id}),
            label = isActive ? 'primary' : ''

        return label
      },
      
      loadModel()
      {
        let ws

        switch( this.modelType )
        {
          case 'book': ws = Api.books.one(this.modelId); break;
          case 'issue': ws = Api.issues.one(this.modelId); break;
          case 'quote': ws = Api.quotes.one(this.modelId); break;
        }

        if( !ws )
          return

        ws.then((res)=>{
          this.model = res.data.data
        })
      },

      loadTags()
      {
        Api.tags.list().then((res)=>{
          this.tags = res.data.data
        })
      },

      toggleTag(tag)
      {
        if( !this.modelType && !this.modelId )
          return;

        this.loading = tag.id
        
        Api.tags.toggle( tag.id, this.modelType, this.modelId )
            .then((res)=>{
              this.loadModel()
              this.loading = null
              this.$emit('toggle', tag, res.data.new_status)
            })
      }
      
    },

    watch: {}

  }
</script>
