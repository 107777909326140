<template>

  <v-dialog v-model="dialog" :fullscreen="$mq=='sm'">

    <v-card>
      <v-card-title
        class="headline red darken-2 white--text"
        primary-title
      >
        <span v-if="quoteId">Edit a quote</span>
        <span v-else>Add a quote</span>
      </v-card-title>

      <v-card-text>

        <v-form>
          <v-container>
            <v-row>

              <v-col cols="12">
                
                <note-editor :content.sync="quote.content"
                             v-if="!quote.image" />

                <v-img :src="quote.content" max-height="250" :contain="true" v-else></v-img>

              </v-col>

              <v-col cols="12 mt-1">

                <v-btn @click="addEllipsis" class="mr-3" title="Add Ellipsis">
                  (...)
                </v-btn>

                <OCR @ocr="onOCR" @snapshot="onSnapshot" ref="ocr"/>

              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Page"
                  v-model="quote.page" 
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-checkbox 
                  v-model="quote.image" 
                  label="Save as image"></v-checkbox>
              </v-col>

            </v-row>
          </v-container>
        </v-form>
        
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="grey">
        <v-btn text dark @click="dialog = false">
          <v-icon class="mr-1">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save">
          <v-icon class="mr-1">mdi-check</v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>

    <Loading :status="overlay"/>

  </v-dialog>

</template>

<script>

  import Api from '../../api'

  import Loading from '../Loading'
  import NoteEditor from '../note/Editor'
  import OCR from '../OCR'

  export default {
    name: 'QuoteModal',

    components: {
      Loading,
      NoteEditor,
      OCR
    },

    props: {
      buttonClass: {
        type: String
      },
      color: {
        type: String
      },
      dark: {
        type: Boolean,
        default: false
      },
      fab: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: ()=> 'mdi-pencil-circle'
      },
      label: {
        type: String,
        default: ()=> ''
      },
      onlyIcon: {
        type: Boolean,
        default: false
      },
      ownerId: {
        type: Number
      },
      ownerType: {
        type: String
      },
      quoteId: {
        type: Number
      }
    },

    data: () => ({
      quote: {
        image: false
      },
      dialog: false,
      overlay: false
    }),

    mounted(){},

    methods: {
      fetchQuote()
      {
        if(this.quoteId)
          Api.quotes.one(this.quoteId).then((res)=>{
            this.quote = res.data.data
          })
      },
      addEllipsis()
      {
        let text = this.quote.content + ' (...) '
        this.$set( this.quote, 'content', text )
      },
      onOCR(text)
      {
        // merge previous OCR
        if(this.quote.content && !this.quote.image)
          text = this.quote.content + text

        this.$set( this.quote, 'image', false )
        this.$set( this.quote, 'content', text )
        this.$refs.ocr.show = false
      },
      onSnapshot(base64)
      {
        this.$set( this.quote, 'content', base64 )
        this.$set( this.quote, 'image', true )
        this.$refs.ocr.show = false
      },
      save()
      {
        if( !this.quote.content )
          return

        if( this.ownerId )
          this.quote.owner_id = this.ownerId

        if( this.ownerType )
          this.quote.owner_type = this.ownerType

        if( !this.quote.owner_type )
          return     
        

        var ws = this.quoteId ? 
                  Api.quotes.update(this.quoteId, this.quote) : 
                  Api.quotes.create(this.quote)

        this.overlay = true
          
        ws.then((res)=>{

          this.overlay = false
          
          this.dialog = false

          this.$emit('save', res.data.data)

        }).catch((error)=>{
          
          console.error(error.response)

          this.$store.commit('growl', {
            color: 'error',
            text: error.response.data.message
          })

          this.overlay = false

        })
      },
      show()
      {
        this.dialog = true
      }
    },

    watch: {
      dialog(isOpening)
      {
        if(isOpening)
          this.fetchQuote()
        else
          this.quote = { image:false }
      }
    },

  }
</script>
