<template>
    <div>
      
      <v-container class="py-0">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                label="ISBN" 
                :autofocus="true"
                ref="theIsbn" 
                v-model="theIsbn"
                v-on:keyup.enter.prevent="lookup"
              ></v-text-field>
            </v-col>
          </v-row>
      </v-container>

      <v-list dense>
        <ScannerBulkItem
          v-for="listItem in barcodes"
          :key="listItem.barcode.code"
          :item.sync="listItem">
        </ScannerBulkItem>
      </v-list>
    </div>
</template>

<script>
  import ListItem from './classes/list-item'
  
  import ScannerBulkItem from './Item'

  export default {
    name: 'ScannerBulkList',

    components: {
        ScannerBulkItem
    },

    props: {
      barcodes: {
        type: Array,
        default: ()=> []
      }
    },

    data: () => ({
      theIsbn: ''
    }),

    computed: {
      hasBarcodes()
      {
        return !!this.barcodes.length
      }
    },

    mounted() {

    },

    methods: {
      lookup()
      {
        var listItem = new ListItem(this.theIsbn)
        
        this.barcodes.push(listItem)
        
        this.resetInput()
      },
      resetInput()
      {
        this.theIsbn = ''
        this.$refs.theIsbn.focus()
      }
    },

    watch: {
      
    }

  }
</script>