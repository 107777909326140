<template>

  <v-dialog v-model="dialog" :fullscreen="$mq=='sm'">
    <v-card>

      <v-card-title
        class="headline red darken-2 white--text"
        primary-title>
        Topics
      </v-card-title>
      
      <v-card-text class="pt-2">
        <TopicsList :model-type="modelType" :model-id="modelId"/>
      </v-card-text>

      <v-card-actions class="grey">
        <v-btn text dark @click="dialog = false">
          <v-icon class="mr-1">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      
    </v-card>
  </v-dialog>

</template>

<script>

  import TopicsList from './Topics'

  export default {
    name: 'TopicsModal',

    components: {
      TopicsList
    },

    props: {
      modelId: {
        type: Number
      },
      modelType: {
        type: String
      }
    },

    data: () => ({
      dialog: false
    }),

    mounted(){},

    methods: {
      show()
      {
        this.dialog = true
      }
    },

    watch: {},

  }
</script>
