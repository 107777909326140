<template>
    <v-card>
      <v-card-title
        class="headline red darken-2 white--text"
        primary-title
      >
        Searching book info
      </v-card-title>

      <v-skeleton-loader
        class="mx-auto"
        type="card" 
      ></v-skeleton-loader>

    </v-card>
</template>

<script>
  export default {
    name: 'BookScannerLoading',

    data: () => ({
      
    }),

    props: {
      
    },

    mounted() {
      
    },

    methods: {

    },

    watch: {
     
    }

  }
</script>

<style>
    
</style>