<template>
  
  <v-container fluid>

    <Quote v-for="quote in sortedQuotes" 
           :key="quote.id"
           :quote="quote" 
           @delete="onDelete" 
           @update="onUpdate"
           :id="`quote-${quote.id}`"/>

    <Message v-if="!sortedQuotes.length">
      No quotes here.
    </Message>

  </v-container>

</template>

<script>

  import _ from 'lodash'
  import Api from '../../api'

  import Message from '../Message'
  import Quote from './Quote'

  import goTo from 'vuetify/lib/services/goto'

  export default {
    name: 'Quotes',

    components: {
      Message,
      Quote
    },

    props: {
      modelId: {
        type: Number,
        required: true
      },
      modelType: {
        type: String,
        required: true
      },
    },

    data: () => ({
      quotes: []
    }),

    computed: {
      sortedQuotes()
      {
        var sorted = _.sortBy(this.quotes, (quote)=>{
          return parseInt(quote.page)
        })

        return sorted
      }
    },

    mounted(){
      this.fetchQuotes()
    },

    methods: {
      onDelete(quote)
      {
        this.quotes = _.reject( this.quotes, quote )
        this.$emit('delete', quote)
      },
      fetchQuotes()
      {
        if( !this.modelId )
          return

        var ws

        switch(this.modelType){
          case 'article':
            ws = Api.articles.quotes(this.modelId)
            break;
          case 'book':
            ws = Api.books.quotes(this.modelId)
            break;
          case 'issue':
            ws = Api.issues.quotes(this.modelId)
        }

        if(ws)
          ws.then((res)=>{
            this.quotes = res.data.data
          })
      },
      onUpdate(quote)
      {
        var index = _.findIndex(this.quotes, {id:quote.id})
        this.quotes.splice(index, 1, quote)
      }
    },

    watch: {
      modelId()
      {
        this.fetchQuotes()
      },
      quotes(quotes)
      {
        // quando aggiorno le quote controllo se ho un anchor
        const route = this.$route
        if( quotes.length && 
            route.hash && 
            route.hash.indexOf('#quote-') >= 0 )
          this.$nextTick(()=>goTo(route.hash))
      }
    }

  }
</script>
