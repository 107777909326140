<template>
  <div>
    <v-skeleton-loader type="list-item,list-item,list-item"
                       v-if="loading"></v-skeleton-loader>

    <v-list two-line v-else-if="tags.length">

      <template v-for="(tag, index) in tags">
        <v-list-item :key="tag.id" 
                     @click="onClick(tag)"
                     :class="isActive(tag) ? 'v-item--active v-list-item--active' : ''">
          <template>
            <v-list-item-content>
              <v-list-item-title v-text="tag.name.toUpperCase()"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action v-if="!withoutCount">
              <v-chip color="primary" 
                      class="ma-2" 
                      v-html="tag.count"></v-chip>
            </v-list-item-action>
          </template>
        </v-list-item>

        <v-divider
          v-if="index < tags.length - 1"
          :key="`sep${index}`"
        ></v-divider>
      </template>

    </v-list>

    <Message v-else>
      <h3 class="headline">No tags here (yet!)</h3>
    </Message>

  </div>
</template>

<style type="text/css">
  
</style>

<script>
  import _ from 'lodash'
  import Api from '../../api'

  import Message from '../Message'

  export default {
    name: 'TagsList',

    components: {
      Message
    },

    props: {
      activeIds: {
        type: Array,
        default(){ return [] }
      },
      withoutCount: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      loading: null,
      tags: []
    }),

    mounted()
    {
      this.loadTags()
    },

    methods: {

      isActive(tag)
      {
        let isActive = (this.activeIds.indexOf(tag.id) >= 0),
            label = isActive ? 'primary' : ''

        return label
      },

      loadTags()
      {
        this.$store.commit('setLoading')

        this.loading = true

        Api.tags.list().then((res)=>{
          
          this.tags = _.sortBy(res.data.data, 'name')

          this.$store.commit('setLoading', false)

        }).finally(()=>this.loading = false)
      },

      onClick(tag)
      {
        this.$emit('click', tag)
      }

    }

  }
</script>
