<template>
    <v-list-item
      @click="onClick"
      :disabled="loading">
      
      <!-- status icon -->
      <v-list-item-icon>
        <!-- loading -->
        <v-progress-circular 
          v-if="loading"
          indeterminate 
          size="20" width="2"></v-progress-circular>
        <!-- selected -->
        <v-icon v-else-if="item.status"
                color="primary">mdi-check-circle</v-icon>
        <!-- unselected -->
        <v-icon v-else>mdi-checkbox-blank-circle</v-icon>
      </v-list-item-icon>
      
      <v-list-item-content>
        <v-list-item-title v-text="item.getCode"></v-list-item-title>
        <v-list-item-subtitle v-text="theTitle"></v-list-item-subtitle>
      </v-list-item-content>
      
      <v-list-item-action>
        <!-- .... -->
      </v-list-item-action>
    
    </v-list-item>
</template>

<script>
  //import _ from 'lodash'
  import Api from '../../api'
  import ListItem from './classes/list-item'
  
  export default {
    name: 'ScannerBulkItem',

    components: {
    },

    props: {
      item: ListItem
    },

    data: () => ({
      loading: false,
      theTitle: null
    }),

    computed: {
      
    },

    mounted() {
      this.checkBarcode()
    },

    methods: {
      checkBarcode()
      {
        if( !this.item.hasCode )
          return

        this.lookupIsbn(this.item.getCode)
      },
      async lookupIsbn(isbn)
      {

        this.loading = true

        this.item.setStatus(false)
        
        try {
          await Api.lookupIsbn(isbn).then((res)=>{
            let book = res.data.data,
                title = book.title.toUpperCase()
            
            // book.authors, book.title, book.cover_url
            this.item.setTitle(title)
            
            this.loading = false

            this.item.setStatus(true)
          })  
        } catch(e) {
          // no ISBN match.
          this.loading = false
        }
      },
      onClick()
      {
        this.item.toggleStatus()
      }
    },

    watch: {
      barcode: {
        handler(newBarcode)
        {
          if( newBarcode.code != this.item.getCode )
            this.checkBarcode()
        },
        deep: true
      }
    }

  }
</script>