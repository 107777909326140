<template>
  
  <v-chip :color="topicColor()" 
          class="lighter-5"
          @click="onClick">
    {{ topic.name.toUpperCase() }}
  </v-chip>

</template>

<script>

  export default {
    name: 'Topic',

    components: {},

    props: {
      topic: {
        type: Object,
        default: ()=>({})
      }
    },

    data: () => ({
    }),

    mounted(){},

    methods: {
      onClick()
      {
        this.$emit('click', this.topic)
      },
      topicColor()
      {
        let color = ""
        
        switch(this.topic.type){
          case 'CONSUMER_GOOD': color = ""; break;
          case 'EVENT': color = "yellow"; break;
          case 'LOCATION': color = "accent"; break;
          case 'NUMBER': color = ""; break;
          case 'ORGANIZATION': color = "primary"; break;
          case 'PERSON': color = "secondary"; break;
          case 'WORK_OF_ART': color = "green"; break;
        }
        
        return color
      }
    }

  }
</script>
