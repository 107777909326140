<template>

  <v-dialog v-model="dialog" 
            :fullscreen="isMobile"
            :max-width="modalMaxWidth">
    <v-card>
      <v-card-title
        class="headline red darken-2 white--text"
        primary-title>
        Tags
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">

        <TagsList :active-ids="activeIds"
                  without-count
                  @click="toggleTag"/>
        
      </v-card-text>

    </v-card>
  </v-dialog>

</template>

<script>
  import api from '../../api'
  import utils from '../../utils'

  import TagsList from './List'

  export default {
    
    name: 'TagsModal',

    mixins: [ utils ],

    components: {
      TagsList
    },

    props: {
      modelId: {
        type: Number
      },
      modelType: {
        type: String
      }
    },

    data: () => ({
      dialog: false,
      model: {},
    }),

    mounted()
    {
      if( this.modelType && this.modelId )
        this.loadModel()
    },

    computed:
    {
      activeIds()
      {
        if( !this.model.tags )
          return []

        return this.model.tags.map((tag)=>tag.id)
      }
    },

    methods: {

      loadModel()
      {
        let ws

        switch( this.modelType )
        {
          case 'book': ws = api.books.one(this.modelId); break;
          case 'issue': ws = api.issues.one(this.modelId); break;
          case 'quote': ws = api.quotes.one(this.modelId); break;
        }

        if( !ws )
          return

        ws.then((res)=>{
          this.model = res.data.data
        })
      },
      
      show()
      {
        this.dialog = true
      },

      toggleTag(tag)
      {
        //this.loading = tag.id
        
        api.tags.toggle( tag.id, this.modelType, this.modelId )
            .then(()=>{
              this.loadModel()
              //this.loading = null
              this.$emit('update')
            })
      }

    },

    watch: {
      modelId(newValue, oldValue)
      {
        if( (!oldValue && !this.model.id) || (newValue && newValue != oldValue) )
          this.loadModel()
      },
      modelType(newValue, oldValue)
      {
        if( (!oldValue && !this.model.id) || (newValue && newValue != oldValue) )
          this.loadModel()
      }
    },

  }
</script>
