<template>
    <v-card>
      <v-card-title
        class="headline red darken-2 white--text"
        primary-title
      >
        Search a Book
      </v-card-title>

      <div class="px-5">

        <v-alert type="info" text
                 v-if="!hideMessage">
          No match found for this barcode. Please try to search the book manually.
        </v-alert>

        <v-radio-group v-model="searchMode" row>
          <template v-slot:label>
          <strong class="black--text">Search book by</strong>
        </template>
          <v-radio label="Title" value="title"></v-radio>
          <v-radio label="ISBN" value="isbn"></v-radio>
        </v-radio-group>

        <v-text-field
          :label="`Book ${searchMode}`"
          :placeholder="`Search by ${searchMode}`"
          outlined 
          :autofocus="true"
          :append-icon="liveTypingIcon"
          v-model="search">
          <v-progress-circular
            :size="20"
            :width="2"
            indeterminate
            color="primary"
            v-if="isLoading"
            slot="append"
          ></v-progress-circular>
        </v-text-field>

        <v-list v-if="results.length">
          <v-list-item-group color="primary">
            <v-list-item two-line 
              v-for="book in results" 
              :key="book.isbn"
              @click="select(book)">
              <v-list-item-icon>
                <v-icon>mdi-book</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <span v-for="author in book.authors" 
                        :key="author.fullname"
                        class="mr-1">
                    {{ author.fullname }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ book.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <Message v-else>No search results.</Message>

      </div>

      <v-card-actions class="grey">
        <v-btn text dark @click="$emit('close')">
          <v-icon class="mr-1">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text dark @click="goToScan">
          <v-icon class="mr-2">mdi-barcode-scan</v-icon>
          SCAN
        </v-btn>
      </v-card-actions>

    </v-card>
</template>

<script>
  import liveTyping from '@/mixins/live-typing'

  import Api from '../../api'
  import debounce from 'debounce'
  import Message from '../Message'

  export default {
    name: 'BookScannerSearch',

    mixins: [ liveTyping ],

    components: {
      Message
    },

    data: () => ({
      isLoading: false,
      search: '',
      searchMode: 'title',
      results: []
    }),

    props: {
      hideMessage: {
        type: Boolean,
        default: false
      }
    },

    mounted() {
      this.lookup = debounce(this.lookup, 500)
    },

    methods: {
      goToScan()
      {
        this.$emit('goToScan')
      },
      lookup(query){

        this.isLoading = true

        if( this.searchMode === 'title' )
          Api.lookupTitle(query).then((res)=>{
            this.results = res.data
          })
          .catch(()=> this.results = [])
          .finally(()=> this.isLoading = false)

        else
          Api.lookupIsbn(query).then((res)=>{
            this.results = [res.data.data]
          })
          .catch(()=> this.results = [])
          .finally(()=> this.isLoading = false)
      },
      select(book){
        this.$emit('selected', book)
      }
    },

    watch: {
      search(query)
      {
        this.results = []
        
        let resolve = () => this.lookup(query)

        this.liveType(resolve)
      },
      searchMode()
      {
        if(this.search)
        {
          let resolve = () => this.lookup(this.search)

          this.liveType(resolve)
        }
      }
    }

  }
</script>

<style>
    
</style>