<template>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      
      <v-card>
        <v-card-title
          class="headline red darken-2 white--text mb-3"
          primary-title
        >
          Scan book barcode
        </v-card-title>

        <ScannerBulkImporter ref="importer"
          :barcodes="selectedBarcodes"
          v-show="isImporting"
          @complete="onComplete">
        </ScannerBulkImporter>

        <ScannerBulkList
          :barcodes.sync="barcodes"
          v-if="!isImporting">
        </ScannerBulkList>

        <v-card-actions class="grey">
          <v-btn text dark @click="dialog = false">
            <v-icon class="mr-1">mdi-close</v-icon>
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="true" 
                 v-if="isImporting">
            <v-progress-circular 
              indeterminate 
              class="mr-1"
              size="20" 
              width="2"></v-progress-circular>
            Saving...
          </v-btn>
          <v-btn color="success" 
                 @click="importBarcodes"
                 v-else-if="hasBarcodes">
            <v-icon class="mr-1">mdi-check</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>

      <Loading :status="overlay"/>

    </v-dialog>
</template>

<script>
  import _ from 'lodash'
  
  import Loading from '../Loading'
  import ScannerBulkImporter from './Importer'
  import ScannerBulkList from './List'

  export default {
    name: 'ScannerBulk',

    components: {
        Loading,
        ScannerBulkImporter,
        ScannerBulkList
    },

    props: {

    },

    data: () => ({
      dialog: false,
      barcodes: [],
      book: null,
      isImporting: false,
      loading: false,
      overlay: false,
      theIsbn: ''
    }),

    computed: {
      hasBarcodes()
      {
        return !!this.selectedBarcodes.length
      },
      selectedBarcodes()
      {
        var selected = _.filter(this.barcodes, { status:true })

        return selected
      }
    },

    mounted() {

    },

    methods: {
      importBarcodes()
      {
        this.isImporting = true

        this.$refs.importer.start()
      },
      onComplete()
      {
        this.isImporting = false
        this.barcodes = []
        this.dialog = false

        this.$store.commit('growl', {
          color: 'success',
          text: 'Books imported.'
        })
      },
      resetInput()
      {
        this.theIsbn = ''
        this.$refs.theIsbn.focus()
      },
      show()
      {
        this.dialog = true
      }
    },

    watch: {
      
    }

  }
</script>