<template>
    <v-card>
      <v-card-title
        class="headline red darken-2 white--text"
        primary-title
      >
        {{ book.title }}
      </v-card-title>

      <div class="px-3">
        <BookRow :book="book"
                  class="my-3"
                  hide-start
                  hide-end/>
      </div>

      <v-divider></v-divider>

      <v-card-actions class="grey">
        <v-btn text dark @click="goToScan">
          <v-icon class="mr-2">mdi-barcode-scan</v-icon>
          SCAN
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save">
          <v-icon class="mr-1">mdi-check</v-icon>
          Save
        </v-btn>
      </v-card-actions>

    </v-card>
</template>

<script>

  import BookRow from '../book/Row'

  export default {
    name: 'BookScannerPreview',

    components: {
      BookRow
    },

    data: () => ({
      
    }),

    props: {
      book: {
        type: Object,
        default: ()=>{ return {} }
      }
    },

    mounted() {
      
    },

    methods: {
      goToScan()
      {
        this.$emit('goToScan')
      },
      save(){
        this.$emit('save', this.book.isbn)
      },

    },

    watch: {
      
    }

  }
</script>

<style>
    
</style>