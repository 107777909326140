<template>
  
  <v-dialog v-model="deleteDialog"
            :max-width="modalMaxWidth">
    <v-card color="red darken-2">
      <v-card-title class="headline white py-6">
        Do you want to delete this quote?
      </v-card-title>
      <v-card-actions>
        <v-btn color="white" text @click="deleteDialog = false">
          <v-icon class="mr-2">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="deleteQuote(quote)">
          <v-icon class="mr-2">mdi-check</v-icon>
          Yes, Delete!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

  import Api from '../../api'
  import utils from '../../utils'

  export default {
    name: 'QuoteDeleteModal',

    mixins: [utils],

    components: {
    },

    props: {
      quote: {
        type: Object,
        default: () => {}
      }
    },

    data: () => ({
      deleteDialog: false
    }),

    mounted(){},

    methods: {
      deleteQuote(quote)
      {
        Api.quotes.delete(quote.id).then(()=>{
            this.deleteDialog = false
            this.$emit('delete', this.quote)
          })
      },
      show()
      {
        this.deleteDialog = true
      }
    }

  }
</script>
