<template>
  
  <v-dialog v-model="dialog" :max-width="modalMaxWidth">
    <v-card>
      <v-card-title class="headline red darken-2 white--text">
        <v-icon class="mr-2 white--text">mdi-eye-off</v-icon>
        Do you want to hide this topic?
      </v-card-title>
      <Message class="mt-3 mx-3" color="warning">
        <v-icon class="mr-1 warning--text">mdi-alert</v-icon>
        Pay attention, this action cannot be undone!
      </Message>
      <v-card-actions class="grey">
        <v-btn text dark @click="dialog = false">
          <v-icon class="mr-1">mdi-close</v-icon> 
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="hideTopic">
          <v-icon class="mr-1">mdi-check</v-icon>
          YES
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

  import Api from '../../api'
  import utils from '../../utils'

  import Message from '../Message'

  export default {
    name: 'HideDialog',

    mixins: [utils],

    components: {
      Message
    },

    props: {
      autoClose: {
        type: Boolean,
        default: true
      },
      topicId: {
        type: Number
      }
    },

    data: () => ({
      dialog: false
    }),

    mounted(){},

    computed: {},

    methods: {
      hideTopic()
      {
        Api.topics.hide(this.topicId)
           .then((res)=>{
            
            this.$emit('submit', res)
            
            if(this.autoClose)
              this.dialog = false

           })
           .catch((error)=>{
            this.$emit('error', error)
           })
      },
      show()
      {
        this.dialog = true
      }
    },

    watch: {}

  }
</script>
