<template>
  <v-dialog
      v-model="dialog"
      :max-width="modalMaxWidth"
      :fullscreen="$mq=='sm'">

    <StepStart :search="search"
               @close="hide"
               @start="goStep(2)"
               v-if="isStep(1)"/>

    <StepSearch :search="search"
                @close="hide"
                @save="saveCover"
                v-else-if="isStep(2)"/>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-dialog>
</template>

<script>
  import Api from '../../api'
  import utils from '../../utils'

  import StepStart from './step/Start'
  import StepSearch from './step/Search'

  export default {
    name: 'SearchCoverModal',

    mixins: [utils],

    components: {
      StepStart,
      StepSearch
    },

    props: {
      book: {
        type: Object,
        default:()=>{ return {} }
      }
    },

    data: () => ({
      dialog: false,
      overlay: false,
      step: 1
    }),

    mounted(){
      
    },

    computed: {
      search()
      {
        return this.book.title
      }
    },

    methods: {
      goStep(step)
      {
        this.step = step
      },
      isStep(step)
      {
        return ( this.step === step )
      },
      hide()
      {
        this.dialog = false
      },
      show()
      {
        this.dialog = true
      },
      reset()
      {
        this.step = 1
      },
      saveCover(cover)
      {
        let isbn = this.book.isbn,
            coverUrl = cover.image
        
        Api.books.importCover( isbn, coverUrl )
                 .then((res)=>{
                  
                    this.hide()

                    this.$store.commit('growl', {
                      color: 'success',
                      text: 'Book cover updated.'
                    })

                    let book = res.data.data

                    this.$emit('save', book)

                 })
      }
    },

    watch: {
      dialog(isOpening)
      {
        if( !isOpening )
          this.reset()
      }
    },

  }
</script>
