<template>
  
  <v-container fluid>

    <Topic v-for="topic in filteredTopics" 
            :key="topic.id"
            class="my-2 mx-3"
            :topic="topic"
            @click="onClick">
    </Topic>

    <TopicsLoader v-if="!isLoaded" />

    <Message v-if="!topics.length && isLoaded">
      No topics here.
    </Message>

    <v-bottom-sheet v-model="bottomSheet" inset>
      <v-list>
        <v-subheader 
          v-if="activeTopic.name" 
          class="headline grey lighten-5">
          {{ activeTopic.name.toUpperCase() }}
        </v-subheader>
        <!-- Hide or show -->
        <v-list-item @click="goToTopic">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-information</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            SHOW TOPIC
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="askHideTopic">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-eye-off-outline</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            HIDE TOPIC
          </v-list-item-title>
        </v-list-item>
        <!-- Forward -->
        <v-list-item @click="askForwardTopic">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-call-merge</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            FORWARD TOPIC
          </v-list-item-title>
        </v-list-item>
        <!-- Network -->
        <v-list-item @click="goToNetwork">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-graph</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            NETWORK
          </v-list-item-title>
        </v-list-item>
        <!-- AI Writer -->
        <v-list-item @click="goToWriter">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-brain</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            AI WRITER
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>

    <ForwardDialog :topicId="activeTopic.id" 
                   ref="forwardDialog"
                   @submit="onForward" />    

    <HideDialog :topicId="activeTopic.id" 
                ref="hideDialog"
                @submit="onHide" />    

    <Loading :status="loading"/>

  </v-container>

</template>

<script>

  import _ from 'lodash'
  import Api from '../../api'

  import SearchResult from '@/classes/searchResult'

  import ForwardDialog from './ForwardDialog'
  import HideDialog from './HideDialog'
  import Loading from '../Loading'
  import Message from '../Message'
  import Topic from './Topic'
  import TopicsLoader from './TopicsLoader'

  export default {
    name: 'Topics',

    components: {
      ForwardDialog,
      HideDialog,
      Loading,
      Message,
      Topic,
      TopicsLoader
    },

    props: {
      filter: {
        type: String,
        default: ''
      },
      letter: {
        type: String,
        default: ''
      },
      modelId: {
        type: Number
      },
      modelType: {
        type: String
      },
      showMessage: {
        type: Boolean,
        default: true
      }
    },

    data: () => ({
      activeTopic: {},
      bottomSheet: false,
      dialog: false,
      isLoaded: false,
      loading: false,
      topics: []
    }),

    mounted(){
      this.fetchTopics()
    },

    computed: {
      filteredTopics()
      {
        let f = this.filter.toLowerCase()
        
        return _.filter(this.topics, (topic)=>{
          return (topic.name.toLowerCase().indexOf(f) >= 0)
        })
      }
    },

    methods: {
      fetchTopics()
      {
        var ws

        if( this.modelId ){
          switch(this.modelType){
            case 'article':
              ws = Api.articles.topics(this.modelId, true, this.letter);
              break;
            case 'book':
              ws = Api.books.topics(this.modelId, true, this.letter);
              break;
            case 'issue':
              ws = Api.issues.topics(this.modelId, true, this.letter);
              break;
            case 'quote':
              ws = Api.quotes.topics(this.modelId, this.letter);
              break;
          }
        }
        else{
          ws = Api.topics.list(this.letter)
        }

        if(!ws){
          this.isLoaded = true
          return
        }

        this.loading = true

        ws.then((res)=>{
            this.isLoaded = true
            this.loading = false
            this.topics = _.orderBy(res.data.data,'name')
            this.$emit('load', true, this.topics)
          })
      },
      goToTopic()
      {
        this.$router.push({ name: 'topic', params: { topicId: this.activeTopic.id } })
      },
      askForwardTopic()
      {
        this.bottomSheet = false

        this.$refs.forwardDialog.show()
      },
      askHideTopic()
      {
        this.bottomSheet = false

        this.$refs.hideDialog.show()
      },
      goToNetwork()
      {
        this.$router.push({ 
          name: 'network', 
          params: { 
            modelType: 'topic',
            modelId: this.activeTopic.id
          } 
        })
      },
      goToWriter()
      {
        var topic = new SearchResult().fromTopic(this.activeTopic)

        this.$router.push({ 
          name: 'writer', 
          query: { 
            with: JSON.stringify([topic]),
          } 
        })
      },
      onForward()
      {
        if(this.showMessage)
          this.$store.commit('growl', {
            color: 'success',
            text: 'Topic forwarded'
          })

        this.fetchTopics()
        
        this.loading = false
      },
      onHide()
      {
        if(this.showMessage)
          this.$store.commit('growl', {
            color: 'success',
            text: 'Topic hidden'
          })

        this.fetchTopics()
        
        this.loading = false
      },
      onClick(topic)
      {
        this.activeTopic = topic
        this.bottomSheet = true
      }
    },

    watch: {
      letter()
      {
        this.fetchTopics()
      }
    }

  }
</script>
