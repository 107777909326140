<template>
  
  <v-container fluid>

    <v-card class="pt-5 lighten-5 mb-5" 
            :color="cardColor"
            :key="quote.id"
            @click.prevent="onCardClick">
      
      <v-card-text class="subtitle-1" 
                   v-html="quote.content" 
                   v-if="!quote.image">
      </v-card-text>

      <v-img :lazy-src="quote.content" 
             max-height="250" 
             :contain="true" 
             v-else>
      </v-img>

      <v-card-actions class="quote-actions">
        <v-list-item class="grow">
          <v-row
            align="center"
            justify="end"
          >

            <slot name="details"></slot>

            <div v-if="showBookDetails"
                 @click.stop="openOwner(quote)">
              <v-icon class="mr-2">mdi-book</v-icon> 
              <strong class="grey--text">{{ quote.owner.title }}</strong>
            </div>

            <v-spacer></v-spacer>

            <div class="quote-metas">
              <span class="subheading mr-2 quote-action"
                    @click.stop="showNotes = true">
                <v-icon>mdi-note</v-icon> 
                {{ quote.notes_count }}
              </span>

              <span class="subheading mr-2 quote-action"
                    @click.stop="$refs.tagsModal.show()">
                <v-icon>mdi-label</v-icon> 
                {{ quote.tags.length }}
              </span>

              <span class="quote-action page-number" v-if="quote.page">
                <span class="subheading mr-2">
                  Page {{ quote.page }}
                </span>
              </span>
            </div>

          </v-row>
        </v-list-item>
      </v-card-actions>

      <Notes model-type="quote" 
             :model-id="quote.id"
             :outlined="true"
             :empty-message="false"
             @noteClick="bottomSheet = true" 
             v-if="showNotes"
             ref="notes"/>

    </v-card>

    <!-- MODAL: ZOOM -->
    <v-dialog v-model="zoomModal" 
              fullscreen>
      <v-card class="grey darken-4">
        <v-img :lazy-src="quote.content" 
               :contain="true"
               @click="zoomModal = false">
        </v-img>
      </v-card>
    </v-dialog>

    <!-- MODAL: DELETE -->
    <QuoteDeleteModal :quote="quote" 
                      @delete="onDelete"
                      ref="deleteModal"/>

    <!-- MODAL: EDIT -->
    <QuoteModal :quote-id="quote.id" 
                @save="onUpdate"
                ref="editModal"/>

    <!-- MODAL: EDIT -->
    <NoteModal @save="onNewNote"
                ref="noteModal"
                :owner-id="quote.id"
                owner-type="quote"
                button-class="d-none"/>

    <!-- MODAL: TAGS -->
    <TagsModal model-type="quote"
               :model-id="quote.id"
               ref="tagsModal"/>

    <!-- MODAL: TOPICS -->
    <TopicsModal model-type="quote"
                 :model-id="quote.id"
                 ref="topicsModal"/>

    <!-- ACTION SHEET -->
    <v-bottom-sheet v-model="bottomSheet" inset>
      <v-list>

        <slot name="actions"></slot>

        <!-- content parsed urls -->
        <v-list-item v-for="(url,i) in contentUrls"
                     @click="popup(url)"
                     :key="`url-${i}`">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-link</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            <strong class="primary--text">
              {{ url.toUpperCase() }}
            </strong>
          </v-list-item-title>
        </v-list-item>
        
        <!-- image zoom -->
        <v-list-item v-if="quote.image"
                     @click="onZoomClick">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-magnify-plus</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            ZOOM
          </v-list-item-title>
        </v-list-item>

        <!-- favourite toggle -->
        <v-list-item @click="toggleFav"
                     color="orange"
                     :input-value="isFav">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-progress-circular
                :size="20"
                :width="1"
                indeterminate
                v-if="isFavLoading"
              ></v-progress-circular>
              <v-icon v-else-if="isFav">
                mdi-star
              </v-icon>
              <v-icon v-else>
                mdi-star-outline
              </v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            FAVOURITE
            <favourite-button ref="favouriteButton" 
                              btn-class="d-none"
                              model-type="quote"
                              :model-id="quote.id"
                              @toggled="onFavToggle"/>
          </v-list-item-title>
        </v-list-item>

        <!-- copy to clipboard -->
        <v-list-item @click="copyToClipboard(quote.content), bottomSheet = false">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-clipboard</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            COPY TO CLIPBOARD
          </v-list-item-title>
        </v-list-item>

        <!-- hotlink -->
        <v-list-item @click="copyHotlinkToClipboard(quote), bottomSheet = false">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-link</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            HOTLINK
          </v-list-item-title>
        </v-list-item>

        <!-- note -->
        <v-list-item @click="$refs.noteModal.show(), bottomSheet = false">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-note</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            NOTE
          </v-list-item-title>
        </v-list-item>

        <!-- tags -->
        <v-list-item @click="$refs.tagsModal.show(), bottomSheet = false">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-label</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            TAGS
          </v-list-item-title>
        </v-list-item>

        <!-- topics -->
        <v-list-item @click="$refs.topicsModal.show(), bottomSheet = false">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-brain</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            TOPICS
          </v-list-item-title>
        </v-list-item>

        <!-- update -->
        <v-list-item @click="$refs.editModal.show(), bottomSheet = false">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-pencil</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            EDIT
          </v-list-item-title>
        </v-list-item>
        
        <!-- delete -->
        <v-list-item color="red" :input-value="true"
                     @click="$refs.deleteModal.show(), bottomSheet = false">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-delete</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            DELETE
          </v-list-item-title>
        </v-list-item>

      </v-list>
    </v-bottom-sheet>

  </v-container>

</template>

<style type="text/css">
  .quote-action {
    padding: 5px 10px 5px 15px;
    background-color: rgba(0,0,0,.1);
    margin-right: -12px;
    border-radius: 15px;
  }
  @media (min-width: 768px) {
    .quote-action.page-number {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  @media (max-width: 767px) {
    .quote-actions {
      text-align: center;
    }
    .quote-metas {
      padding: 20px 0 10px;
      width: 100%;
    }
  }
</style>

<script>

  import _ from 'lodash'
  import favourites from '@/mixins/favourites'
  import utility from '@/mixins/utility'
  import Utility from '@/utils'

  import FavouriteButton from '../FavouriteButton'
  import NoteModal from '../note/Modal'
  import Notes from '../note/Notes'
  import QuoteModal from './Modal'
  import QuoteDeleteModal from './DeleteModal'
  import TagsModal from '../tag/TagsModal'
  import TopicsModal from '../topic/TopicsModal'

  export default {
    name: 'Quote',

    mixins: [ favourites, utility ],

    components: {
      FavouriteButton,
      NoteModal,
      Notes,
      QuoteModal,
      QuoteDeleteModal,
      TagsModal,
      TopicsModal
    },

    props: {
      bookDetails: {
        type: Boolean,
        default: false
      },
      quote: {
        type: Object,
        default: () => {}
      },
      withoutMenu: {
        type: Boolean,
        default: false
      },
      zoom: {
        type: Boolean,
        default: () => true
      },
    },

    data: () => ({
      bottomSheet: false,
      isFavLoading: false,
      showNotes: false,
      showTags: false,
      zoomModal: false
    }),

    computed: {
      cardColor()
      {
        return this.isFav ? 'yellow' : 'grey'
      },
      contentUrls()
      {
        var urls = _.isEmpty(this.quote.content) ? [] : this.quote.content.match(/\bhttps?:\/\/\S+/gi)

        return urls
      },
      isFav()
      {
        return this.isFavQuote(this.quote)
      },
      showBookDetails()
      {
        return this.bookDetails && this.quote.owner
      }
    },

    mounted(){},

    methods: {
      copyHotlinkToClipboard(quote)
      {
        const hotlink = `${location.origin}${location.pathname}#quote-${quote.id}`
        this.copyToClipboard(hotlink)
      },
      onCardClick()
      {
        if( !this.withoutMenu )
          this.bottomSheet = true
      },
      onDelete(quote)
      {
        this.$emit('delete', quote)
      },
      onFavToggle()
      {
        this.isFavLoading = false
        this.$store.dispatch('loadFavourites')
        this.bottomSheet = false
      },
      onNewNote()
      {
        this.$refs.notes.fetchNotes() 
      },
      onUpdate(updatedQuote)
      {
        this.$emit('update', updatedQuote)
      },
      onZoomClick()
      {
        if( this.zoom && this.quote.image ){
          this.zoomModal = true
          this.bottomSheet = false
        }
      },
      openOwner(quote)
      {
        var owner = Utility.methods.getModelType(quote.owner_type),
            ownerId = quote.owner_id,
            route

        switch(owner.name){
          case 'article':
              route = { name: 'article', params: { articleId: ownerId } }
            break;
          case 'book':
              route = { name: 'book', params: { bookId: ownerId } }
            break;
        }

        this.$router.push(route)
      },
      popup(url)
      {
        window.open(url)
        this.bottomSheet = false
      },
      toggleFav()
      {
        this.isFavLoading = true
        this.$refs.favouriteButton.toggleFav()
      }
    }

  }
</script>
