<template>
    <div>
      <v-container>
        <v-progress-linear :value="progress" 
                           height="10"
                           :color="barColor">
        </v-progress-linear>
      </v-container>

      <v-list dense>
        <v-list-item :disabled="true"
                     v-for="item in barcodes"
                     :key="item.barcode.code"
                     :class="item.isImported?'light-green lighten-4':''">
          
          <!-- status icon -->
          <v-list-item-icon>
            <!-- in queue -->

            <!-- importing -->
            <v-progress-circular 
              v-if="!item.isImported"
              indeterminate 
              size="20" width="2"></v-progress-circular>
            <!-- imported -->
            <v-icon v-else
                    color="success">mdi-check-circle</v-icon>
          </v-list-item-icon>
          
          <v-list-item-content>
            <v-list-item-title v-text="item.getCode"></v-list-item-title>
            <v-list-item-subtitle v-text="item.getTitle"></v-list-item-subtitle>
          </v-list-item-content>
          
          <v-list-item-action>
            <!-- .... -->
          </v-list-item-action>
        
        </v-list-item>
      </v-list>
    </div>
</template>

<script>
  import _ from 'lodash'
  import axios from 'axios'
  import Api from '../../api'

  export default {
    name: 'ScannerBulkImporter',

    components: {

    },

    props: {
      barcodes: {
        type: Array,
        default: ()=> []
      }
    },

    data: () => ({
      loading: true
    }),

    computed: {
      barColor()
      {
        return this.progress < 100 ? 'primary' : 'success'
      },
      completedCount()
      {
        return _.filter(this.barcodes, { isImported: true }).length
      },
      hasBarcodes()
      {
        return !!this.barcodes.length
      },
      progress()
      {
        return this.completedCount / this.barcodes.length * 100
      }
    },

    mounted() {

    },

    methods: {
      start()
      {
        var promises = this.barcodes
                           .map((barcode)=> Api.importIsbn(barcode.getCode, {}, {
                            onDownloadProgress(e)
                            {
                              console.log(e)
                              barcode.isImported = true
                            }
                           }))

        axios.all(promises)
             .then(()=>{
              this.$emit('complete')
             })
             .catch((e)=>{
              console.log(e)
             })

      }
    },

    watch: {
      
    }

  }
</script>