<template>
  
  <v-row align="start">
    <v-col cols="6" md="2" v-for="i in 6" :key="i">
      <v-sheet
        color="grey lighten-4"
        class="pa-3"
      >
        <v-skeleton-loader type="article" class="w-100"></v-skeleton-loader>
      </v-sheet>
    </v-col>
  </v-row>

</template>

<script>

  export default {
    name: 'TopicsLoader',

    components: {},

    props: {},

    data: () => ({
    }),

    mounted(){},

    methods: {}

  }
</script>
