//import _ from 'lodash'
import Barcode from '../../../classes/barcode'

var ListItem = class {

	barcode = {}

	status = true

	isImported = false

	constructor(code)
	{
		var barcode = new Barcode(code)
		
		this.setBarcode(barcode)
	}

	setBarcode(barcode)
	{
		this.barcode = barcode
	}

	get getBarcode()
	{
		return this.barcode
	}

	get hasBarcode()
	{
		return !!this.barcode
	}

	get getCode()
	{
		return this.barcode.code
	}

	get hasCode()
	{
		return !!this.barcode.code
	}

	get getTitle()
	{
		return this.barcode.getTitle
	}

	setTitle(title)
	{
		this.barcode.setTitle(title)
	}

	setStatus(status)
	{
		this.status = !!status
	}

	toggleStatus()
	{
		this.status = !this.status
	}
}

export default ListItem