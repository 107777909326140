<template>
  <v-card class="pt-3">

    <v-container class="dialog-h100-actions pt-0">

      <v-card color="grey lighten-4" 
              class="px-3 mb-3"
              v-if="showFilters"
              elevation="2">
        <v-row>
          <v-col cols="6" class="pb-0">
            <v-text-field
              label="APPEND QUERY" 
              v-model="filters.query"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="pb-0">
            <small>IMAGES TO SHOW</small>
            <v-slider
              hint="Items count"
              max="50"
              min="1"
              v-model="filters.count"
            ></v-slider>
          </v-col>
          <v-col cols="2" class="pb-0 text-center">
            <v-btn fab color="primary" @click="fetchResults">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <div class="text-center" v-if="!isLoading">
        <v-btn text @click="showFilters=!showFilters">
          <v-icon class="mr-1">mdi-filter</v-icon>
          {{ showFilters ? 'HIDE':'SHOW' }}
           FILTERS
        </v-btn>
      </div>

      <!-- loading-->
      <div class="text-center py-2 w-100" v-if="isLoading">
        <v-progress-circular indeterminate 
                             color="primary"
                             size="40" 
                             width="3"></v-progress-circular>
      </div>

      <v-container v-else>
        <!-- with results -->
        <v-row v-if="filteredImages.length">
          <v-col :cols="$mq=='sm'?12:3"
                 v-for="(image,i) in filteredImages"
                 :key="'image-'+i">
            <v-img :src="image.image"
                   @click="selectImage(image)"
                   :class="{ selected:isSelected(image) }"
                   class="pointer"/>
          </v-col>
        </v-row>
        <!-- without results -->
        <Message v-else>
          No cover found on Google.
        </message>
      </v-container>

    </v-container>
      
    <v-card-actions class="grey">
      <!-- close modal -->
      <v-btn text dark @click="onClose">
        <v-icon class="mr-1">mdi-close</v-icon>
        Close
      </v-btn>
      <v-spacer></v-spacer>
      <!-- save cover -->
      <v-btn color="success" 
             @click="onSave"
             v-if="selected">
        <v-icon class="mr-1">mdi-check</v-icon>
        Save
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<style type="text/css">
  .v-image.selected {
    border: 5px solid #1976d2;
    box-shadow: 0 0 20px #1976d2;
  }
</style>

<script>
  import _ from 'lodash'
  import Api from '../../../api'

  import Message from '../../Message'

  export default {
    name: 'SearchCoverModalStepSearch',

    components: {
      Message
    },

    props: {
      search: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      filters: {
        count: 8,
        query: ''
      },
      images: [],
      isLoading: false,
      selected: null,
      showFilters: false
    }),

    created(){
      this.fetchResults()
    },

    computed: {
      filteredImages()
      {
        return _.take(this.images, this.filters.count)
      },
      searchString()
      {
        return this.search + ' ' + this.filters.query
      }
    },

    methods: {
      fetchResults()
      {
        this.isLoading = true

        this.showFilters = false

        Api.searchImages(this.searchString)
           .then((res)=>{
              this.images = res.data
              this.isLoading = false
           })
      },
      onClose()
      {
        this.$emit('close')
      },
      onSave()
      {
        this.$emit('save', this.selected)
      },
      isSelected(image)
      {
        return ( this.selected &&
                 this.selected.image === image.image )
      },
      selectImage(image)
      {
        if( this.isSelected(image) )
          this.selected = null

        else
          this.selected = image
      }
    },

    watch: {
      
    },

  }
</script>
