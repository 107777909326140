<template>

  <v-dialog v-model="dialog"
            :max-width="modalMaxWidth">

    <v-card color="red darken-2">
      <v-card-title class="headline white py-6">
        Are you sure you want to permanently delete tag <strong class="primary--text">{{ tag.name }}</strong>?
      </v-card-title>
      <v-card-actions>
        <v-btn color="white"
          text
          @click="dialog = false"
        >
          <v-icon class="mr-2">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text
          color="white"
          @click="save"
        >
          <v-icon class="mr-2">mdi-check</v-icon>
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>

    <Loading :status="overlay"/>

  </v-dialog>

</template>

<script>

  import Api from '../../api'
  import utils from '../../utils'

  import Loading from '../Loading'

  export default {

    name: 'DeleteModal',

    mixins: [utils],

    components: {
      Loading
    },

    props: {
      autoClose: {
        type: Boolean,
        default: true
      },
      tag: {
        type: Object,
        default: ()=>{}
      }
    },

    data: () => ({
      dialog: false,
      overlay: false
    }),

    mounted(){},

    methods: {
      save()
      {
        var ws = Api.tags.delete(this.tag.id)

        this.overlay = true
          
        ws.then((res)=>{

          this.overlay = false
          
          if(this.autoClose)
            this.dialog = false

          this.$store.commit('growl', {
            color: 'success',
            text: 'Tag deleted.'
          })

          this.$emit('save', res.data.data)

        }).catch((error)=>{
          
          console.error(error.response)

          this.$store.commit('growl', {
            color: 'error',
            text: error.response.data.message
          })

          this.overlay = false

        })
      },
      show()
      {
        this.dialog = true
      }
    },

    watch: {},

  }
</script>
