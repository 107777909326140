<template>
  <v-card flat 
          @click="bottomSheet=true"
          class="pointer lighten-5"
          :color="cardColor">
    <!-- prenend slot -->
    <slot name="prepend"></slot>
    <!-- title -->
    <div class="text-h6 primary--text mb-4">
      <v-icon>{{ item.icon }}</v-icon>
      {{ item.text }}
    </div>
    <!-- description -->
    <div v-html="item.description"></div>
    <!-- slot -->
    <slot></slot>

    <v-bottom-sheet v-model="bottomSheet" inset>
      <v-list>
        <!-- open result -->
        <v-list-item @click="openSearchResult(item)">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-link</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            OPEN RESULT
          </v-list-item-title>
        </v-list-item>
        <!-- open result -->
        <v-list-item @click="openSearchResult(item, true)">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-share</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            OPEN IN NEW WINDOW
          </v-list-item-title>
        </v-list-item>
        <!-- copy to clipboard -->
        <v-list-item @click="copy(item.description), bottomSheet=false">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-clipboard</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            COPY TO CLIPBOARD
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="copyToClipboard(item.description), bottomSheet=false">
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon>mdi-language-html5</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            COPY AS HTML
          </v-list-item-title>
        </v-list-item>
        <!-- actions slot -->
        <slot name="actions"></slot>
      </v-list>
    </v-bottom-sheet>

  </v-card>
</template>

<script>
  import search from '@/mixins/search'
  import utility from '@/mixins/utility'

  import SearchResult from '@/classes/searchResult'

  export default {
    
    name: 'SearchResult',

    mixins: [ search, utility ],

    components: {
      
    },

    props: {
      item: {
        type: SearchResult,
        required: true
      }
    },

    data: () => ({
      bottomSheet: false
    }),

    mounted()
    {
      
    },

    computed: {
      
      cardColor()
      {
        return this.item.isFav ? 'yellow' : ''
      },

    },

    methods: {
      
      copy(what)
      {
        const text = this.stripHtml(what)
        this.copyToClipboard(text)
      }

    }

  }
</script>
