<template>

  <v-dialog v-model="dialog" :fullscreen="$mq=='sm'">

    <v-card>
      <v-card-title
        class="headline red darken-2 white--text"
        primary-title
      >
        <span v-if="tagId">Edit a tag</span>
        <span v-else>Add a tag</span>
      </v-card-title>

      <v-card-text>

        <v-form>
          <v-container>
            <v-row>

              <v-col cols="12">
                <v-text-field
                  label="Name"
                  v-model="tag.name" 
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-form>
        
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="grey">
        <v-btn text dark @click="dialog = false">
          <v-icon class="mr-1">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save">
          <v-icon class="mr-1">mdi-check</v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>

    <Loading :status="overlay"/>

  </v-dialog>

</template>

<script>

  import Api from '../../api'

  import Loading from '../Loading'

  export default {
    name: 'TagModal',

    components: {
      Loading
    },

    props: {
      tagId: {
        type: Number
      }
    },

    data: () => ({
      tag: {
        name: ''
      },
      dialog: false,
      overlay: false
    }),

    mounted(){},

    methods: {
      fetchTag()
      {
        if(this.tagId)
          Api.tags.one(this.tagId).then((res)=>{
            this.tag = res.data.data
          })
      },
      save()
      {
        var ws = this.tagId ? 
                  Api.tags.update(this.tagId, this.tag) : 
                  Api.tags.create(this.tag)

        this.overlay = true
          
        ws.then((res)=>{

          this.overlay = false
          
          this.dialog = false

          this.$emit('save', res.data.data)

        }).catch((error)=>{
          
          console.error(error.response)

          this.$store.commit('growl', {
            color: 'error',
            text: error.response.data.message
          })

          this.overlay = false

        })
      },
      show()
      {
        this.dialog = true
      }
    },

    watch: {
      dialog(isOpening)
      {
        if(isOpening)
          this.fetchTag()
        else
          this.tag = { name:'' }
      }
    },

  }
</script>
