<template>
  
  <v-dialog v-model="dialog" :max-width="modalMaxWidth">
    <v-card>
      <v-card-title class="headline red darken-2 white--text">
        <v-icon class="mr-2 white--text">mdi-call-merge</v-icon>
        Forward a topic
      </v-card-title>
      
      <v-card-text class="pt-4 text-h6" style="font-weight:normal">Choose the target topic you want to forward this one to.</v-card-text>

      <div class="mx-3">
        <v-autocomplete
          v-model="target"
          :items="topics"
          :loading="isLoading"
          :search-input.sync="search"
          color="white"
          autofocus
          clearable
          placeholder="Search a topic"
          prepend-inner-icon="mdi-magnify"
          return-object
          solo-inverted
          flat>
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.text"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </div>

      <v-card-actions class="grey">
        <v-btn text dark @click="dialog = false">
          <v-icon class="mr-1">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="forwardTopic"
          v-if="target"
        >
          <v-icon class="mr-1">mdi-check</v-icon>
          SAVE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

  import _ from 'lodash'
  import Api from '../../api'
  import utils from '../../utils'

  export default {
    name: 'ForwardDialog',

    mixins: [utils],

    components: {
    },

    props: {
      autoClose: {
        type: Boolean,
        default: true
      },
      topicId: {
        type: Number
      }
    },

    data: () => ({
      dialog: false,
      isLoading: false,
      search: '',
      target: null,
      topics: []
    }),

    mounted(){},

    computed: {},

    methods: {
      forwardTopic()
      {
        Api.topics.forward(this.topicId, this.target.value)
           .then((res)=>{
            
            this.$emit('submit', res)
            
            if(this.autoClose)
              this.dialog = false

           })
           .catch((error)=>{
            this.$emit('error', error)
           })
      },
      show()
      {
        this.dialog = true
      }
    },

    watch: {
      search (val) {

        if( !val ) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        Api.search(val, {models:['topics']})
          .then(res => {
            let topics = _.chain(res.data.topics)
                          .filter((topic)=>{
                            return topic.id!=this.topicId
                          })
                          .map((topic)=>{
                            return {
                              text: topic.name.toUpperCase(),
                              value: topic.id
                            }
                          })
                          .value()
            this.$set(this, 'topics', topics)
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },
    }

  }
</script>
