<template>
  <v-card class="pt-3">

    <v-container class="dialog-h100-actions">
      <p class="text-center display-1">
        Do you want to search a cover on web for
        <strong>{{ search }}</strong>?
      </p>
    </v-container>

    <v-card-actions class="grey">
      <v-btn text dark @click="onClose">
        <v-icon class="mr-1">mdi-close</v-icon>
        Close
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" 
             @click="onSearch">
        <v-icon class="mr-1">mdi-image-search</v-icon>
        Search
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
  export default {
    name: 'SearchCoverModalStepStart',

    components: {},

    props: {
      search: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      
    }),

    mounted(){
      
    },

    computed: {

    },

    methods: {
      onClose()
      {
        this.$emit('close')
      },
      onSearch()
      {
        this.$emit('start')
      }
    },

    watch: {
      
    },

  }
</script>
